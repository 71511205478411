<template>
  <div class="login-container">
    <!-- <svg-icon class="logo" icon-class="logo" /> -->
    <img src="../../assets/loginlogo.png" class="logo"/>
    <div class="des" ref="des">
      <!-- <svg-icon icon-class="kouhao1" style="width:100%;height:auto" /> -->
      <img src="../../assets/kouhao.png" style="width:100%;height:auto"/>
    </div>
    <div class="login-form-layout">
      <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" auto-complete="on" label-position="left">
        <div v-if="isCode" class="title-container">
          <h3 class="title">颜色管理系统</h3>
          <!--<lang-select class="set-language"/>-->
          <!-- 下划线 -->
          <div class="line"></div>
        </div>
        <div v-else class="title-container">
          <h3 style="margin-left:50px;">手机验证</h3>
          <h5 class="title_">为了您的账号安全，请输入手机验证码进行验证</h5>
          <div class="line" style="width:320px;"></div>
        </div>
        <div v-if="isCode" class="landing-content">
          <el-form-item prop="username" style="margin: 30px auto">
            <span class="svg-container" style="font-size: 24px">
              <svg-icon icon-class="user" />
            </span>
            <el-input v-model="loginForm.username" placeholder="用户名或邮箱" name="username" type="text" auto-complete="on" />
          </el-form-item>

          <el-form-item prop="password" style="margin: 30px auto">
            <span class="svg-container">
              <svg-icon icon-class="password" />
            </span>
            <el-input v-model="loginForm.password" :type="passwordType" placeholder="密码" name="password" auto-complete="on" @keyup.enter.native="handleLogin" />
            <span class="show-pwd" @click="showPwd">
              <svg-icon :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'" />
            </span>
          </el-form-item>

          <el-form-item prop="captcha" v-if="loginForm.err_size >= 3">
            <span class="svg-container">
              <span class="el-icon-picture" />
            </span>
            <el-input name="captcha" type="text" @keyup.enter.native="handleLogin" v-model="loginForm.captcha" autocomplete="on" placeholder="请输入验证码" />
            <img :src="imgUrl" @click="vCode" class="vcode">
          </el-form-item>
          <el-button :loading="loading" type="primary" style="width: 320px" @click.native.prevent="handleLogin">登录</el-button>
          <div class="forget" @click="goForget">
            忘记密码
          </div>
        </div>
        <div v-if="!isCode" class="landing-content">
          <el-form-item prop="phone_" style="margin: 30px auto">
            <span>
              <!-- <svg-icon icon-class="user" /> -->手机号
            </span>
            <el-input v-model="loginForm.phone_" placeholder="手机号码" name="phone_" type="text" auto-complete="on" :disabled="true"/>
          </el-form-item>

          <el-form-item prop="code" style="margin: 30px auto" class="code">
            <span>
              <!-- <svg-icon icon-class="pa" /> -->验证码
            </span>
            <el-input v-model="loginForm.code" type="text" placeholder="验证码" name="code" auto-complete="on" @keyup.enter.native="handleLogin" />
            <div class="send-code">
              <el-button size="small" class="code_" :style="{color:!disabled ? '' : '#ffffff',border:!disabled ? '' : 'none',backgroundColor:!disabled ? '' : 'rgba(90, 90, 90, 0.3)'}" @click="getCode" :disabled="disabled">{{tips}}</el-button>
            </div>
          </el-form-item>
          <el-button :loading="loading" type="primary" style="width: 200px;margin-left:60px;margin-bottom:20px;" @click.native.prevent="handleLogin">确定</el-button>
          <div class="forget_" style="width:200px;margin-left:60px;text-align:right;font-size:14px;">
            无法收到短信？请联系管理员
          </div>
        </div>
      </el-form>
    </div>
    <div style="display: flex;justify-content: space-around;color: #ffffff;font-size: 14px;width: 100%;bottom: 20px;position: fixed;">
      <p>商务联系 丨 技术支持：colorx_robot@aliyun.com</p>
      <p>© 2022 亿涂（苏州）互联网科技有限公司 版权所有 | <a href="http://beian.miit.gov.cn/">苏ICP备2022011139号</a></p>
    </div>
  </div>
</template>
<script>
import { isvalidUsername, guid } from "@/utils/validate";
import { sendCode, login } from '@/api/login';
import { Message } from "element-ui";
import Cookies from "js-cookie";
import resize from "@/utils/resize";
export default {
  name: "login",
  mixins: [resize],
  data() {
    const validatePassword = (rule, value, callback) => {
      if (value.length < 4) {
        callback(new Error("密码不能少于4位数"));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        username: "",
        password: "",
        phone: "",
        phone_: "",
        code: "",
        uuid: "",
        captcha: "",
        err_size: "0",
      },
      isCode:true,
			tips: '获取验证码',
      disabled:false,
      loginRules: {
        username: [
          { required: true, trigger: "blur", message: "请输入用户名或邮箱" }, //validator: validateUsername
        ],
        // phone: [
        //   { required: true, trigger: "blur", message: "手机号码不能为空" }, //validator: validateUsername
        // ],
        code: [
          { required: true, trigger: "blur", message: "验证码不能为空" }, //validator: validateUsername
        ],
        password: [
          { required: true, trigger: "blur", validator: validatePassword },
        ],
        captcha: [{ required: true, trigger: "blur", message: "请输入验证码" }],
      },
      passwordType: "password",
      loading: false,
      showDialog: false,
      imgUrl: "",
    };
  },
  created() {
    this.loginForm.err_size = Cookies.get("ERR_SIZE");
    this.loginForm.uuid = guid();
    this.imgUrl =
      process.env.VUE_APP_BASE_API + `captcha.jpg?uuid=${this.loginForm.uuid}`;
  },
  methods: {
    // 跳转页面
    goForget() {
      //console.log(1111111111)
      this.$router.push("/forget")
    },
    showPwd() {
      if (this.passwordType === "password") {
        this.passwordType = "text";
      } else {
        this.passwordType = "password";
      }
    },
    getCode() {
      if(!this.loginForm.phone){ 
        Message.error('请输入手机号码');
        return
      }
      if(!(/^1[3456789]\d{9}$/.test(this.loginForm.phone))){ 
        Message.error('手机号码格式错误');
        return
      }
      this.validateBtn()
      var params = {
        mobile: this.loginForm.phone,
        userName: this.loginForm.username
      }
      sendCode(params).then(({ data }) => {
        Message.success("验证码已发送！");
      })
		},
    validateBtn(){
        //倒计时
        let time = 120;
        let timer = setInterval(() => {
        if(time == 0) {
          clearInterval(timer);
          this.disabled = false;
          this.tips = "获取验证码";
        } else {
          this.tips =time + '秒后重试';
          this.disabled = true;
          time--
        }
      },1000)
    },
    handleLogin() {
      if (this.isCode) {
        this.loginForm.code = ''
      }else{
        this.loginForm.captcha = ''
        this.loginForm.uuid = ''
      }
      console.log(this.loginForm)
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$store
            .dispatch("Login", this.loginForm)
            .then((res) => {
              if (res.code == 0) {
                //Cookies.set("deviceId", "");
                Cookies.set("ERR_SIZE", "0");
                this.$router.push({ path: "/" });
                if (Cookies.getJSON('devicedata')) {
                  Cookies.remove('devicedata');
                }
                if (res.dateExpire) {
                  this.$message({
                    message: '您的服务有效期已不足90天',
                    type: 'warning',
                    showClose:true,
                    duration: 0,
                  })
                }
              } else if (res.code == '10401') {
                // this.$router.push("/loginCode")
                this.isCode = false
                this.loginForm.phone = res.msg
                this.loginForm.phone_ = res.msg.substring(0, 3)+"****"+res.msg.substr(7)
              } else {
                var err_size = parseInt(this.loginForm.err_size) + 1;
                this.loginForm.err_size = err_size + '';
                Cookies.set("ERR_SIZE", this.loginForm.err_size);
                Message.error(res.msg);
              }
              this.loading = false;
              this.vCode();
            })
            .catch((err) => {
              this.loginForm.err_size = 3;
              Cookies.set("ERR_SIZE", "3");
              Message.error(err);
              this.loading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 点击更新验证码
    vCode() {
      const uuid = guid();
      this.loginForm.uuid = uuid;
      this.imgUrl = process.env.VUE_APP_BASE_API + `captcha.jpg?uuid=${uuid}`;
    },
  },
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

$bg: #283443;
$light_gray: #eee;
$cursor: #fff;
/* reset element-ui css */
.login-container {
  background: rgba(13, 78, 230, 0.06) url("../../assets/Background.png") center
    center no-repeat;
  .logo {
    // width: 126px;
    // height: 76px;
    width: 187px;
    height: auto;
    position: fixed;
    top: 5%;
    left: 3.9%;
  }
  .des {
    position: fixed;
    top: 50%;
    margin-top: -140px;
    // margin-top: -139.5px;
    left: 10%;
    max-width: 731px; 
  }
  .login-form-layout {
    position: absolute;
    top: 50%;
    left: 58%;
    margin-top: -270px;
    background: #fefdfd;
    box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.18);
    opacity: 1;
    border-radius: 10px;
    .forget {
      width: 56px;
      height: 20px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 20px;
      color: #000000;
      opacity: 1;
      cursor: pointer;
      margin-right: 10px;
      float: right;
      margin-top: 10px;
    }
  }
  .landing-content {
    width: 320px;
    height: 249px;
    margin: 0 auto;
  }
  .landing-content ::v-deep {
    .el-input__inner {
      border: 0;
    }
  }
  .el-input {
    display: inline-block;
    height: 47px;
    width: 85%;
    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 0px;
      height: 47px;
      caret-color: $cursor;
      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px $bg inset !important;
        -webkit-text-fill-color: $cursor !important;
        background-color: transparent !important;
      }
    }
  }
  .el-form-item {
    width: 320px;
    border-bottom: 1px solid #706ca5;
  }
  .el-button--primary {
    width: 320px;
    height: 50px;
    background: #42d6d7;
    opacity: 1;
    border-radius: 5px;
    margin-top: 8px;
    border-color: white;
  }
  .el-input:-webkit-autofill:focus {
    // -webkit-box-shadow: none!important;
    // -webkit-text-fill-color: white;
  }
}
.login-container .el-input input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px #f7f7f7 inset !important;
  -webkit-text-fill-color: #000 !important;
}
</style>

<style rel="stylesheet/scss" lang="scss" scoped>
// $bg:#2d3a4b;
$bg: transparent;
$dark_gray: #2e414e;
$light_gray: #eee;
.login-container {
  min-height: 100%;
  width: 100%;
  overflow: hidden;
  background-size: cover;
  .vcode {
    position: absolute;
    width: 100px;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
  }
  .login-form {
    width: 420px;
    //height: 430px;
    max-width: 100%;
    overflow: hidden;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 30px 0;
  }
  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;
    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }
  .svg-container {
    padding: 6px 5px 6px 1px;
    color: $dark_gray;
    vertical-align: middle;
    width: 40px;
    display: inline-block;
    font-size: 21px;
  }
  .title-container {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    //padding: 50px 0 30px 0;
    .title {
      width: 120px;
      height: 28px;
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: bold;
      line-height: 28px;
      color: #000000;
      opacity: 1;
      margin: 10px auto;
    }
    .title_ {
      height: 28px;
      line-height: 28px;
      color: #666;
      opacity: 1;
      margin: 10px;
      padding-left: 40px;
    }
    .line {
      width: 80px;
      height: 3px;
      background: #42d6d7;
      opacity: 1;
      margin: 0 auto;
    }
    .set-language {
      color: #fff;
      position: absolute;
      top: 3px;
      font-size: 18px;
      right: 0px;
      cursor: pointer;
    }
  }
  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }
  .code{
    // width: 220px;
		margin-bottom: 10px;
    // margin:30px 0 !important;
		position: relative;
	}
	.send-code {
		width: 170px;
		text-align: right;
		position: absolute;
		top: 0px;
		right: 10px;
	}
	.code_{
		border: 1px solid #595757;
		background-color: #FFFFFF;
		color: #595757;
		font-size: 12px;
	}
}
@media screen and (min-width: 1024px) {
  .des {
    width: 450px;
  }
} /*>=1024的设备屏幕*/

@media screen and (min-width: 1100px) {
  .des {
    width: 450px;
  }
} /*>=1100的设备屏幕*/
@media (min-width: 1280px) {
  .des {
    width: 500px;
  }
} /*>=1280的设备屏幕*/

@media screen and (min-width: 1366px) {
  .des {
    width: 531px;
  }
} /*>=1366的设备屏幕*/

@media screen and (min-width: 1440px) {
  .des {
    width: 631px;
  }
} /*>=1440的设备屏幕*/

@media screen and (min-width: 1680px) {
  .des {
    width: 731px;
  }
} /*>=1680的设备屏幕*/
@media screen and (min-width: 1920px) {
  .des {
    width: 731px;
  }
} /*>=1920的设备屏幕*/
img {   
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;    
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
}
</style>
